<template>
    <div class='coordination-upload-container'>
        <el-dialog 
        title="发起协同"
		width="900px"
         @close='closeEvent'
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            <el-row>
                <el-col :span="12">
                    <div class='team-left'>
                        <div v-if='files && files[0]' class="coordination-upload-file">
                            <div>
                                <el-image :src='files[0].coverImg | thumbnailUrl'>
                                    <div slot="error" class="image-slot" style="background:#efefef;text-align:center;height:200px;box-sizing:border-box;padding-top:70px;">
                                        <p style="margin:5px 0;color:#999">暂未生成</p>
                                        <p style="margin:0;color:#999">缩略图</p>
                                    </div>
                                </el-image>
                            </div>
                            <div>
                                <h1>{{files[0].name}}</h1>
                                <p>文档类型：<span>{{files[0].suffix}}</span></p>
                                <p>文档大小：<span>{{files[0].size | byteSize}}</span></p>
                                <!-- <p>文档页数：<span>页</span></p> -->
                                <p>创建时间：<span>{{files[0].createDate}}</span></p>
                                <div>
                                    <el-button size='small' @click="toSelectMyDocument">重新选择</el-button>
                                </div>
                            </div>
                        </div>
                        <el-upload
                            class="upload-demo"
                            drag
                            :limit="1"
                            ref="upload"
                            action="none"
                            :auto-upload="false"
                            :on-change="onUploadFile"
                            :show-file-list="false">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">请拖拽文档到这里自动上传，或点击 <em @click.stop="toSelectMyDocument">我的文档</em> 选择已有文档</div>
                        </el-upload>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div>
                        <div class="send-type-style">协同方式：
                            <el-radio-group v-model="teamWorkType" >
                                <el-tooltip content="所有参与者都可以同时编辑"><el-radio :label="0">协同处理</el-radio></el-tooltip>
                                <el-tooltip content="按照参与者顺序编辑，只有上一位编辑完成提交后，下一位才会收到协同信息"><el-radio :label="1" :disabled='teamDisabled'>顺序处理</el-radio></el-tooltip>
                            </el-radio-group>
                        </div>
                        <StepUserInfo :targets="targetList"  viewerKey='teamWork' :shareInfo='teamInfo' ref="stepUserInfo"></StepUserInfo>
                        <div class="tips"><i class="el-icon-info"></i> 提示：顺序处理可拖拽单元格进行授权目标排序；顺序协同不可以选组织部门，创建成功后也不可更改协同目标。</div>
                    </div>
                </el-col>
            </el-row>
         
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeEvent">取 消</el-button>
                <el-button type="primary" @click="onSubmit" :disabled='files.length  == 0' v-loading ='loading'>创建协同</el-button>
            </span>
        </el-dialog>

		<!-- 文件上传并保存位置 -->
		<SaveUploadFile ref='SaveUploadFile' ></SaveUploadFile>

        <!-- 选择我的文档 -->
        <MyDocument ref='MyDocument' ></MyDocument>

        <!-- 创建成功 -->
        <TeamSuccess ref='TeamSuccess'></TeamSuccess>
    </div>
</template>
<script>
import SaveUploadFile from './SaveUploadFile.vue';
import MyDocument from './SelectMyDocument.vue';
import StepUserInfo from '@/packages/FileCart/src/StepUserInfo.vue'
import TeamSuccess from './teamSuccess.vue';
  export default {
	components:{
		SaveUploadFile,
        MyDocument,
        StepUserInfo,
        TeamSuccess
	},
    data() {
      return {
        dialogVisible: false,
        viewerKey:'teamWork',
		loading:false,//是否转换完成
        teamDisabled:false,//是否可以选择顺序执行，
      };
    },
	computed:{
		//文件列表  目前是单文件协同，逻辑支持多文件
		files() {
			const viewer = this.$store.state.teamWork.views[this.viewerKey]
			return viewer ? viewer.docList : []
		},
        // 协同类型
        teamWorkType:{
            get() {
                return this.$store.state.teamWork.teamInfo.teamWorkType
            },
            set(value) {
                this.$store.commit("teamWork/updateReamType", value)
            }
        },
        //目标列表
        targetList() {
            const targets = this.$store.state.teamWork.targets
            return Object.keys(targets).map(k => targets[k])
        },
        //文件列表  目前是单文件协同，逻辑支持多文件
		teamInfo() {
			return this.$store.state.teamWork.teamInfo
		}
	},
    watch:{
        targetList: {
            handler(newVal, oldVal) {
                if(newVal.length != 0){
                    let arr = newVal.filter(m => m.type === 'TARGET_ORG' ||  m.type === 'TARGET_GROUP' );
                    if(arr.length != 0){
                        this.teamDisabled = true;
                        this.$store.commit("teamWork/updateReamType", 0)
                    }else{
                        this.teamDisabled = false;
                    }
                }else{
                     this.teamDisabled = false;
                }
               
            },
            deep: true
        }
    },
    methods: {
        // 清除数据并销毁视图
        closeEvent(){
            this.dialogVisible = false;
            this.$store.commit('teamWork/resetData');
            this.$store.commit('teamWork/destroyViewer', 'teamWork');
        },
        // 初始化创建视图
        init(){
            this.dialogVisible = true;
            this.$store.commit('teamWork/createViewer', {
                viewerKey: this.viewerKey
            })
        },
        // 创建协同任务
        onSubmit(){
            // 检测是否转换完成，针对新上传的文件
            let filesIdList = this.files.map(item=>item.id)
            this.$store.dispatch('doc/batchCheckDocumentSharePermission',filesIdList).then(res=>{
                if(res.code == 0){
                    if(res.data.length > 0){
                        this.$message.error('当前文档未转换完成，请稍后重试！')
                    }else{
                        this.loading = true;
                        this.$store.dispatch('teamWork/createTeamWork',{
                            targetList:this.targetList
                        }).then( res  => {
                            if(res.code == 0){
                                this.$message.success('创建成功');
                                this.$emit("clearSearch")
                                this.closeEvent() ;
                                
                                // let  teamUrl = this.$options.filters.teamWorkUrl(res.data.id);
                                // this.$refs['TeamSuccess'].show(teamUrl)

                            }else{
                                this.$message.error(res.message);
                            }
                           
                            this.loading = false;
                        })
                    }
                }
                
            })
           
        },
        // 上传文档
        onUploadFile(file){
            this.$refs['upload'].clearFiles();
            this.$refs['SaveUploadFile'].show(file);
        },
		// 从我的文档中选择文件
		toSelectMyDocument(){
            this.$refs['MyDocument'].show()
		},
    },
    
  };
</script>
<style lang="less" scoped>
.coordination-upload-container{
	.upload-demo{
		width: 100%;
		/deep/.el-upload{
			width: 100%;
			.el-upload-dragger{
				width: 100%;
			}
		}
	}
    .team-left{
        padding-right: 20px;
    }
    .send-type-style{
        margin-bottom: 10px;
    }
    .coordination-upload-file{
        margin-bottom: 10px;
        display: flex;
        h1{
            margin-top: 3px;
        }
        .el-image{
            border: 1px solid #efefef;
            width: 150px;
            height: auto;
            margin-right: 15px;
        }
        
    }
    .dialog-footer{
        display: flex;
        justify-content: center;
    }
    .tips{
        font-size: 12px;
        color: #999;
    }
}
</style>