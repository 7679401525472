<template>
    <el-dialog title="文档协同" :visible.sync="dialogVisible"
    :append-to-body='true'
    :close-on-click-modal="false" width="450px" @close='closeDialog'>
    <!-- 共享模式 -->
    <template>
        <div class="tips-box">
            <i class="el-icon-success"></i>
            文档创建协同成功，下方为创建协同二维码
        </div>
        <div class="share-box">
             <div class="share-modular">
                <h1 class="share-modular-title"><span>微信扫码即可获取文件</span></h1>
                 <div class="qr-code share-link">
                    <qr ref='qr' :margin="10" :text="shareUrl" colorDark="#333333" colorLight="#fff" :size="120"></qr>
                </div>
                <div>
                    <el-button type="primary" size='small' @click="downloadQrCode">点击下载二维码</el-button>
                </div>
            </div>
        </div>
    </template>
  </el-dialog>
</template>
<script>
import qr from 'vue-qr'
export default {
    components:{
        qr
    },
    data(){
        return {
            shareUrl:'',
            dialogVisible:false
        }
    },
    methods:{
        show(url){
            this.shareUrl = url;
            this.dialogVisible = true;
        },
        closeDialog(){
            this.shareUrl = '';
             this.dialogVisible = false;
        },
        toMyShare(){
            this.$router.push({
                path:'/sharetion/sharedoc',
                query:{
                    active:'second'
                }
            })
        },
        downloadQrCode(){
            let base64 = this.$refs.qr.$el.src; // imgSrc 就是base64哈
            let byteCharacters = atob(
                base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
            );
            let byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {
                type: undefined,
            });
            let aLink = document.createElement("a");
            aLink.download = "qrCode.jpg"; //这里写保存时的图片名称
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        }
    },
   
}
</script>
<style lang='less' scoped>
.tips-box{
    text-align: center;
    i{
        font-size: 30px;
        color: #1296db;
        vertical-align: middle;
        margin-right: 0px;
    }
    span{
        color: #1296db;
        cursor: pointer;
    }
}
.share-box{
    display: flex;
    padding-left: 30px;
    padding-bottom: 20px;
    justify-content: center;
    .share-link{
        height: 150px;
    }

    .share-modular{
        margin-bottom: 10px;
        text-align: center;

        .permission-box{
            .el-switch{
            margin-right: 20px;
            }
            .el-date-editor{
            margin:0 15px 0 5px;
            }
        }
        .share-modular-title{
            margin: 0;
            span{
                font-size: 14px;
                color: #999;
                font-weight: normal;
                margin-left: 10px;
            }
        
        }
    }
}
</style>