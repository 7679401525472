<template>
    <div class="uni-flex" v-if='documentBreadcrumb.length != 0'>
        <div class="flex-item" @click="doFolderBack">返回上一级<div class="separate">|</div></div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(item,index) in documentBreadcrumb" :key="index" @click.native="handleBreadClick(item,index)">
               {{item.name}} 
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
<script>
  export default {
    props: {
      documentBreadcrumb: {
        type: Array,
        default: []
      }
    },
    methods:{
      handleBreadClick(item,index){
        this.$emit('handleBreadClick',item,index)
      },
      doFolderBack(){
        this.$emit('doFolderBack')
      }
    }
  }
</script>
<style lang="less" scoped>
.uni-flex{
  border-top: 2rpx solid #fff;
  display: flex;
  color: #999;
  align-items: center;
  padding:10rpx 30rpx ;
  font-size: 16px;
  /deep/.el-breadcrumb{
    font-size: 16px;
    cursor: pointer;
  }
  .flex-item{
    display: flex;
    cursor: pointer;
    align-items: center;
    .separate{
      padding: 0 10px;
      color: #999;
    }
  }
}
</style>
