<template>
    <div>
        <el-dialog
		v-loading='loading'
        title="保存位置"
        :close-on-click-modal="false" 
        width="400px"
        :visible.sync="dialogVisible">
            <div v-if='uploadFile'>
                <p>文档名称：<span>{{uploadFile.name}}</span></p>
                <p>文档大小：<span>{{uploadFile.size | byteSize}}</span></p>
                <p>文档格式：<span>{{uploadFile.type}}</span></p>
                <p>保存位置：<span style="margin-right:15px;">我的文档</span></p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="onSubmit">上 传</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import SparkMD5 from "spark-md5";
import { teamWorkAPI } from 'liankong-ui-api' 
export default {
	data() {
		return {
			dialogVisible: false,
			uploadFile:null,//待上传的文档
			paths:null,//保存位置
			loading:false,
		};
	},
	methods: {
		// 初始化
		show(file){
			this.dialogVisible = true;
			this.uploadFile = file;
			this.uploadFile.type = file.name.substring(file.name.lastIndexOf('.')+1)
		},
		// 修改保存位置并上传和转换
		onSubmit(){		
			this.loading = true;
			this.uploadFile.folderId =this.paths == null ? 0: this.paths.id;
            this.uploadFile.docType = 0
			this.$options.filters["fileMD5"](this.uploadFile, this.progressHandle)

		},
		// 接受返回的目录
		toReceivePath(paths){
			this.paths = paths;
		},
		fileMD5(file){
			return new Promise((resolve,reject)=>{
				const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
				const fileReader = new FileReader()
				const chunkSize = 2097152
				const chunks = Math.ceil(file.size / chunkSize)
			
				let currentChunk = 0
				const spark = new SparkMD5.ArrayBuffer()
				fileReader.onload = function (e) {
					spark.append(e.target.result)
					currentChunk++
					if (currentChunk < chunks) {
					loadNext()
					} else {
					const sparkEnd = spark.end()
						//计算后的结果
						resolve({
							state: "end",
							md5: sparkEnd
						})
					}
				}
				fileReader.onerror = function () {
					reject({message:"读取文件出错"}) 
				}
				function loadNext () {
					const start = currentChunk * chunkSize
					const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
					fileReader.readAsArrayBuffer(blobSlice.call(file.raw, start, end))
				}
				loadNext()
			})
		},
		 //文件处理进度
		progressHandle(err, data){
			if(err){
				return
			}
			if(data.state === "end"){
				this.uploadFile.md5 = data.md5;
				
				this.$store.dispatch("upload/addUploadTask",  this.uploadFile)
				.then( result => {
					this.$message.success('上传成功')
					if(result.documentId){
						this.getDocumentDetail(result.documentId)
					}else if(result.data){
						this.getDocumentDetail(result.data)
					}
					
					this.uploadFile = null;
					this.paths = null;

					
				})
				.catch(error => {
					this.$message.error(error || error.message);
					this.loading = false;
					this.dialogVisible = false;
				});
			}
		},
		// 获取文档详情
		getDocumentDetail(id){
			this.$store.dispatch('teamWork/getDocumentDetail',{ id })
			.then(state => {
				this.loading = false
				this.dialogVisible = false;
			})
			.catch(err => {
				this.loading = false;
				this.$message.error(err)
				this.dialogVisible = false;
			})
		}

	}
};
</script>
<style lang="less" scoped>

</style>