<template>
  <el-dialog
    v-if='visible'
    :title=" checkType == 'file' ? '选择我的文档':'选择保存目录'"
    :close-on-click-modal="false" 
    @close='closeEvent'
    :visible.sync="visible"  width="35%">
    <!-- 面包屑及返回上一级 -->
    <Breadcrumb @doFolderBack='doFolderBack' @handleBreadClick='handleBreadClick' :documentBreadcrumb='documentBreadcrumb'></Breadcrumb>
    
    <div class="list">
      <div v-for="item in viewer.showFiles" :key="item.id" class="list-item"  @click="handleCommand('click',item)">
            <dl >
                <dt>
                    <div v-if='item.type != 0' @click.stop.passive>
                        <el-radio v-model="selectFile" :label="item.id"></el-radio>
                    </div>
                    <div v-else class="space"> </div>
                    <img :src="thumbIcon(item)" alt="">
                </dt>
                <dd>
                    <p style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;height:auto"> {{item.name}}</p>
                    <p> 
                        <span>{{item.ownerName}}</span>
                        <span> | </span>
                        <span>{{ item.createDate }}</span>
                    </p>
                </dd>
            </dl>
            
            
      </div>
    </div>
    <div v-if='checkType == "folder"'>
        已选目录：<span>我的文档</span>
        <span v-if='documentBreadcrumb.length != 0'>
         <span v-for="(item,index) in documentBreadcrumb" :key="index"> &gt; {{item.name}}</span>
        </span>
    </div>
   <!-- 分页 -->
   <div class="pagebox" >
        <el-pagination background layout="prev, pager, next" :total="viewer.paging.total" :page-size="pageSize" @current-change="pageChanged"></el-pagination>
   </div>

    <span slot="footer">
      <div class="btn">
          
          <el-button size="small" @click="visible = false">取消</el-button>
          <el-button type="primary" size="small" @click="onSubmit">确定</el-button>
      </div>
    </span>

  </el-dialog>
</template>
<script>
import iconFolder from '@/assets/img/folder.png'
import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'
import Breadcrumb from './BreadCrumb.vue'


//后缀映射
const suffixIconCache = {
  def: iconDefault,
  folder: iconFolder,
  unknown: iconUnknown,
  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,
  ofd: iconOFD,
  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,
  mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
export default {
    components:{
        Breadcrumb
    },
    props:{
        checkType:{
            type:String,
            default:'file'
        }
    },
    data(){
        return{
            visible:false,
            viewerKey: 'my', // 获取视图数据
            selectFile:null
        }
    },
    computed:{
        viewer() {
            return this.$store.state.doc.viewer[this.viewerKey] 
        },
        pageSize() {
            return 10;
        },
        //文档面包屑
        documentBreadcrumb() {
            return this.$store.state.doc.viewer[this.viewerKey].paths || []
        },
        //文件列表
		files() {
			const viewer = this.$store.state.teamWork.views['teamWork'];
			return viewer ? viewer.docList : []
		},
    },
    methods:{
        // 初始化数据
        show(){
            this.visible = true;
            if(this.files.length != 0){
                this.selectFile = this.files[0].id
            }
            this.loadAndRefresh(1)
        },
        // 关闭弹框数据重置
        closeEvent(){
            this.$store.state.doc.viewer['my'].current = null;
            this.$store.state.doc.viewer['my'].paths = []
            this.$store.state.doc.viewer['my'].depth = 0 
        },
        //加载或刷新当前文件夹
        loadAndRefresh(pageNum) {
            this.$store.dispatch("doc/loadChildren", {
                vkey: this.viewerKey,
                pageSize:this.pageSize,
                pageNum:pageNum,
                fileType:this.checkType == "file" ? '' : '0',
                reset: true,
            });
        },
        
        pageChanged(num) {
            this.loadAndRefresh(num)
        },
         //文档集中处理器
        handleCommand(type, row) {
            switch (type) {
                case "click":
                    if (row.type == 0) {
                        //打开文件夹
                        this.handleEnterFolder(row);
                    } else {
                        if(row.documentId !=null){
                            row.id =row.documentId
                        }
                        this.$ebus.$emit('publicReader',{
                            shareId:row.shareId, 
                            id:row.documentId || row.id,
                            documentId: row.documentId,
                            name:row.name
                        })
                      
                    }
                break;
            }
        },
        // 进入目录
        handleEnterFolder(folder) {
            this.$store.dispatch("doc/enterFolder", {
                vkey: this.viewerKey,
                folder,
                fileType:this.checkType == "file" ? '' : '0',
            });
        },
        //文件目录返回
        doFolderBack() {
            this.$store.dispatch("doc/backFolder", {
                vkey: this.viewerKey
            })
        },
        //刷新
        onRefresh() {
            this.$store.dispatch("doc/loadByQuery", {
                vkey: this.viewerKey,
                reset: true,
                queryInfo:{
                    id:this.viewer.current?this.viewer.current.id:0,
                    pageNum:1,
                    fileType:this.checkType == "file" ? '' : '0',
                    pageSize:this.pageSize
                }
            })
        },
        // 面包屑点击
        handleBreadClick(item,index){
            this.$store.state.doc.viewer['my'].current = item;
            this.$store.state.doc.viewer['my'].paths = this.$store.state.doc.viewer['my'].paths.slice(0,index+1)
            this.$store.state.doc.viewer['my'].depth = this.$store.state.doc.viewer['my'].paths.length
            this.onRefresh()
        },
          /**
         * 图标
         */
        thumbIcon(doc){
            let iconKey = "unknown"
            if(doc.documentType==1 &&doc.orgId!=null){
                iconKey='org'
            }else if(doc.documentType == 2 &&doc.orgId!=null){
                iconKey='depart'
            }else{
                if(doc.type == 0){
                    iconKey = "folder"
                }else if(suffixIconCache.hasOwnProperty(doc.suffix)){
                    iconKey = doc.suffix
                }
            }

            return suffixIconCache[iconKey] 
        },
        // 确定选中文件
        onSubmit(){
            if(this.checkType == 'file'){
                if(this.selectFile){
                    let file = this.viewer.showFiles.filter(m =>m.id == this.selectFile)
                    // 选中的文件，单选
                    this.$store.commit('teamWork/clear', "teamWork")
                    this.$store.commit('teamWork/push', {
                        viewerKey: 'teamWork',
                        doc: file[0],
                    })
                }
                
            }else{
                // 选中的目录
                let paths = this.$store.state.doc.viewer['my'].paths;
                if(paths.length != 0){
                    this.$emit('toReceivePath',paths[paths.length-1])
                }
                
            }
           
            this.visible = false;
        }
       

    },
}
</script>
<style lang='less' scoped>
/deep/ .el-dialog__body {
    padding: 10px 20px 15px 20px !important;
}
/deep/ .el-checkbox__input {
    margin-bottom: 14px;
}
.list{
    height: 350px;
    overflow-y: auto;
    .list-item {
        width: 100%;
        dl {
            width: 100%;
            display: flex;
            padding: 0;
            margin: 30px 0;
            align-items: center;
            cursor: pointer;
            dt {
                display: flex;
                align-items: center;
                margin-top: 15px;
                width: 7%;
                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                    margin-top: -12px;
                }
                /deep/.el-radio__label{
                    display: none;
                }
                .space{
                    width: 15px;
                    height: 14px;
                    display: inline-block;
                }
            }
            dd {
                width: 93%;
                box-sizing: border-box;
                padding: 0;
                margin: 0;
                padding-left: 20px;
                p:nth-child(1){
                    margin: 0;
                    padding-bottom: 6px;
                }
                p:nth-child(2){
                    color: #999;
                    margin: 0;
                }
            }
        }
    }
}
</style>
